<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('make_up_exam')"
                    :isColumns="true"
                    :is-filter="true"
                    :is-columns="true"
                    :actions="subMenu"
                    @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('make_up_exam')"
                    :isColumns="true"
                    :is-filter="true"
                    :is-columns="true"
                    :actions="subMenu"
                    @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter 
                v-show="datatable.filterStatus"
                @filter="$refs.datatable.getRows()"
                @filterClear="$refs.datatable.filterClear()"
                :export-excel="true"
                @exportExcel="$refs.datatable.excel()"
            >
                <ValidationObserver ref="filterForm">
                    <b-row>
                        <b-col sm="3">
                            <b-form-group :label="$t('student_number')">
                                <b-form-input v-model="datatable.queryParams.filter.student_number"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group :label="$t('name')">
                                <b-form-input v-model="datatable.queryParams.filter.name"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group :label="$t('surname')">
                                <b-form-input v-model="datatable.queryParams.filter.surname"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group :label="$t('course_code')">
                                <course-auto-complete v-model="datatable.queryParams.filter.course_code" resultType="code"/>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group :label="$t('is_take_exam')">
                                <YesNoSelectbox v-model="datatable.queryParams.filter.is_take_exam"/>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group :label="$t('letter_grade')">
                                <grade-selectbox 
                                    :grading-system-id-required="false" 
                                    v-model="datatable.queryParams.filter.letter_grade"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group :label="$t('final_letter_grade')">
                                <grade-selectbox 
                                    :grading-system-id-required="false"
                                    v-model="datatable.queryParams.filter.final_letter_grade"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group :label="$t('program_level')">
                                <ValidationProvider name="level" rules="required" v-slot="{ valid, errors }">
                                    <program-level-selectbox-l-y-l
                                        :validateError="errors[0]"
                                        v-model="datatable.queryParams.filter.level"
                                    ></program-level-selectbox-l-y-l>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <datatable 
                ref="datatable"
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                v-show="datatable.showTable"
                :data-function="callService"
            ></datatable>
            <CommonModal 
                ref="addExamScore" 
                :onHideOnlyX="true" 
                @bHideModalHeaderClose="closeModal" 
                v-if="checkPermission('makeupexam_entrylettergrade')"
            >
                <template v-slot:CommonModalTitle>
                    {{ $t('add_exam_score') }}
                </template>
                <template v-slot:CommonModalContent>
                    <add-exam-score 
                        :student="student" v-if="process_exam_score" @success="addedExamScoreAsSucces"
                    ></add-exam-score>
                </template>
            </CommonModal>
            <CommonModal ref="calcPublishModal" :onHideOnlyX="true" @bHideModalHeaderClose="hideCalcPublishModal">
                <template v-slot:CommonModalTitle>
                    {{ modalAction == 'calculate' ? $t('calculate') : modalAction == 'publish' ? $t('publish') : null}}
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="modalForm">
                            <b-row>
                                <b-col sm="8">
                                    <b-form-group :label="$t('program_level')">
                                        <ValidationProvider name="level" rules="required" v-slot="{ valid, errors }">
                                            <program-level-selectbox-l-y-l
                                                :noOL="true"
                                                :validateError="errors[0]"
                                                v-model="programLevel"
                                            ></program-level-selectbox-l-y-l>
                                        </ValidationProvider>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="4" class="mt-2">
                                    <b-button block class="mt-4" variant="primary" @click="modalClick">
                                        {{modalAction == 'calculate' ? $t('calculate') : $t('publish')}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter"
import Datatable from "@/components/datatable/Datatable"
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete.vue"
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox"
import GradeSelectbox from "@/components/interactive-fields/GradeSelectbox.vue"
import ProgramLevelSelectboxLYL from "@/components/interactive-fields/ProgramLevelSelectboxLYL"
import CommonModal from "@/components/elements/CommonModal.vue"
import {ValidationProvider, ValidationObserver} from "vee-validate"
import MakeUpExamService from "@/services/MakeUpExamService"
import AddExamScore from "@/modules/makeUpExam/pages/AddExamScore.vue"

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        ValidationObserver,
        ValidationProvider,
        Datatable,
        CourseAutoComplete,
        YesNoSelectbox,
        GradeSelectbox,
        ProgramLevelSelectboxLYL,
        CommonModal,
        AddExamScore
    },
    metaInfo() {
        return {
            title: this.$t("make_up_exam")
        }
    },
    data() {
        return {
           datatable: {
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-6-line',
                                callback: ({id}) => {
                                    this.$swal
                                        .fire({
                                            text: this.$t('are_you_sure_to_delete'),
                                            showCancelButton: true,
                                            confirmButtonText: this.$t('yes'),
                                            cancelButtonText: this.$t('no')
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                MakeUpExamService.deleteById(id)
                                                    .then((response) => {
                                                        this.$toast.success(this.$t('api. ' + response.data.message))
                                                        this.$refs.datatable.getRows()
                                                    })
                                                    .catch((error) => {
                                                        this.$toast.error(this.$t('api. ' + error.data.message))
                                                    })
                                            }
                                        })
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: "name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: "surname",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: "student_number",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("course_code"),
                        field: "course_code",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("course_name"),
                        field: ('course_name'),
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("letter_grade"),
                        field: "letter_grade",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("final_letter_grade"),
                        field: "final_letter_grade",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("is_take_exam"),
                        field: "is_take_exam",
                        formatFn: (value) => {
                            if (value == null && value == '') return '-'
                            return value ? this.$t('yes') : this.$t('no')
                        },
                        hidden: false,
                        sortable: false,
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "-id",
                    page: 1,
                    limit: 20,
                },
            },
            student: {},
            process_exam_score: null,
            subMenu: [
                {
                    text: this.$t('calculate'),
                    class: 'ri-device-recover-line',
                    callback: () => {
                        this.showCalcPublishModal('calculate')
                    }
                },
                {
                    text: this.$t('publish'),
                    class: 'ri-hotspot-line',
                    callback: () => {
                        this.showCalcPublishModal('publish')
                    }
                }
            ],
            modalAction: null,
            programLevel: null
        }
    },
    created() {
        this.filterSet()
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {}
        },
        callService(config) {
            return MakeUpExamService.getAll(config)
        },
        showCalcPublishModal(action) {
            this.programLevel = null
            this.modalAction = action
            this.$refs.calcPublishModal.$refs.commonModal.show()
        },
        hideCalcPublishModal() {
            this.modalAction = null
            this.programLevel = null
            this.$refs.calcPublishModal.$refs.commonModal.hide()
        },
        async modalClick() {
            const isValid = await this.$refs.modalForm.validate()

            if (isValid) {
                if(this.modalAction == 'calculate' && this.programLevel == 'L' && this.checkPermission('makeupexam_calculatemakeupexam')) {
                    MakeUpExamService.calculate().then(response => {
                        this.$toast.success(this.$t('proccess_success'))
                        this.hideCalcPublishModal()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.'+e.data.message))
                    })
                } else if(this.modalAction == 'calculate' && this.programLevel == 'YL' && this.checkPermission('makeupexam_calculatedegreemakeupexam')){
                    MakeUpExamService.calculateDegree().then(response => {
                        this.$toast.success(this.$t('proccess_success'))
                        this.hideCalcPublishModal()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.'+e.data.message))
                    })
                } else if(this.modalAction == 'publish' && this.programLevel == 'L' && this.checkPermission('makeupexam_publishmakeupexam')){
                    MakeUpExamService.storePublish().then(response => {
                        this.$toast.success(this.$t('proccess_success'))
                        this.hideCalcPublishModal()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.'+e.data.message))
                    })
                } else if(this.modalAction == 'publish' && this.programLevel == 'YL' && this.checkPermission('makeupexam_publishdegreemakeupexam')){
                    MakeUpExamService.storePublishDegree().then(response => {
                        this.$toast.success(this.$t('proccess_success'))
                        this.hideCalcPublishModal()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.'+e.data.message))
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            }
        },
        openAddExamScore(student) {
            this.student = student
            this.process_exam_score = true
            this.$refs.addExamScore.$refs.commonModal.show()
        },
        closeModal() {
            this.process_exam_score = false
            this.getRows()
            this.$refs.addExamScore.$refs.commonModal.hide()
        },
        addedExamScoreAsSucces() {
            this.getRows()
            this.$refs.addExamScore.$refs.commonModal.hide()
        }
    }
}
</script>
